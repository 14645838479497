import api from '@api/Api';
import { IWorkRetentionPage } from '@src/pages/dashboard/WorkRetention.page';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

export interface languagePair {
  startLanguage: string;
  endLanguage: string;
}

interface RetentionPayload {
  baseYear?: string | number | undefined;
  companyIdList?: string[];
  projectIdList?: string[];
  languagePairList?: languagePair[];
}

export function useWorkRetention(pageType: string) {
  const queryClient = useQueryClient();
  const [responseData, setResponseData] = useState();
  const [loading, setLoading] = useState(false);

  const postMonthData = async (payload: IWorkRetentionPage) => {
    return await api.postPms(`/retention/month`, payload).then((res) => {
      return res.data;
    });
  };
  const postYearData = async (payload: IWorkRetentionPage) => {
    return await api.postPms(`/retention/year`, payload).then((res) => {
      return res.data;
    });
  };
  const postQuarterData = async (payload: IWorkRetentionPage) => {
    return await api.postPms(`/retention/quarter`, payload).then((res) => {
      return res.data;
    });
  };

  const { mutateAsync: postMonthFilter } = useMutation({
    mutationFn: postMonthData,
    onMutate: () => {
      setLoading(true); // 요청 시작 시 로딩 시작
    },
    onSuccess: (data) => {
      setResponseData(data);
      queryClient.invalidateQueries([pageType]);
    },
    onError: (error) => {
      console.log('업무리텐션 월별 post 오류', error);
    },
    onSettled: () => {
      setLoading(false); // 요청 완료 시 로딩 종료
    }
  });

  const { mutateAsync: postYearFilter } = useMutation({
    mutationFn: postYearData,
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: (data) => {
      setResponseData(data);
      queryClient.invalidateQueries([pageType]);
    },
    onError: (error) => {
      console.log('업무리텐션 연도별 post 오류', error);
    },
    onSettled: () => {
      setLoading(false);
    }
  });

  const { mutateAsync: postQuarterFilter } = useMutation({
    mutationFn: postQuarterData,
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: (data) => {
      setResponseData(data);
      queryClient.invalidateQueries([pageType]);
    },
    onError: (error) => {
      console.log('업무리텐션 분기별 post 오류', error);
    },
    onSettled: () => {
      setLoading(false);
    }
  });

  return {
    postMonthFilter,
    postYearFilter,
    postQuarterFilter,
    responseData,
    loading
  };
}
