import { Box } from '@mui/material';
import { useMyInfo } from '@src/hooks/myInfo/getMyInfo';
import { useEffect, useMemo } from 'react';
import { TBody } from './TBody';
import styled from '@emotion/styled';
import _ from 'lodash';
import { useGetListByDay } from '@src/hooks/languageTask/getListByDay';
import { IconOutsourcingOnUrl } from '@src/resources/icon';

/**
 *
 * 언어작업 탭 테이블
 *
 */
export function Table({
  listByDayData,
  label,
  type,
  taskFilter,
  setCateGoryCounts,
  setLanguagePairCounts,
  refetchLanguageTaskList,
  setOpen
}: any) {
  const { myInfo } = useMyInfo();

  const list = Array.isArray(listByDayData?.languageTaskList)
    ? _.sortBy(listByDayData.languageTaskList, ['endDateTime', 'desc'])
    : [];

  useEffect(() => {
    if (typeof setCateGoryCounts === 'function' && Array.isArray(list)) {
      setCateGoryCounts((old: any) => {
        const rs: any = { ...old };
        const labelCounter: any = (rs[label] = {});
        list.forEach((item: any) => {
          const k = item.languageTaskStatus;
          // const { languageTaskId, startLanguage, destinationLanguage } = item;
          if (labelCounter[k] === undefined) labelCounter[k] = 1;
          else labelCounter[k]++;
        });
        return rs;
      });

      setLanguagePairCounts((old: any) => {
        const rs: any = { ...old };
        const labelCounter: any = (rs[label] = {});
        list.forEach((item: any) => {
          const { languageTaskStatus, startLanguage, destinationLanguage } =
            item;
          if (languageTaskStatus === 'NEW') {
            const k = `${startLanguage}»${destinationLanguage}`;
            if (labelCounter[k] === undefined) labelCounter[k] = 1;
            else labelCounter[k]++;
          }
        });
        return rs;
      });
      const el = document.querySelector(`.chip.${label}`);
      if (el) {
        el.textContent = Array.isArray(list) ? String(list.length) : '';
      }
    }
  }, []);

  return (
    <TableWrap>
      {Array.isArray(list) && list.length > 0 ? (
        list.map(
          (
            item: {
              projectTitle: string;
              companyName: string;
              languageTaskId: number;
              languageTaskStatus: string;
              taskTitle: string;
              taskType: string;
              totalGrossAmount: number;
              totalNetAmount: number;
              startDateTime: string;
              endDateTime: string;
              fileRegisterStatus: boolean;
              mainProjectManager: {
                userId: number;
                name: string;
                avatarImgUrl: string;
                status: string;
              };
              subProjectManager: {
                userId: number;
                name: string;
                avatarImgUrl: string;
                status: string;
              };
              startLanguage: string;
              destinationLanguage: string;
              totalWorkerList: any[];
              languageSubTaskList: any[];
            },
            i: number
          ) => (
            <TBody
              key={i}
              item={item}
              myInfo={myInfo}
              refetchLanguageTaskList={refetchLanguageTaskList}
            />
          )
        )
      ) : (
        <tbody>
          <Box
            component="tr"
            sx={{
              backgroundColor: '#fff',
              height: 46,
              '&>td': {
                textAlign: 'center',
                verticalAlign: 'middle'
              }
            }}
          >
            <td>해당하는 작업이 없습니다.</td>
          </Box>
        </tbody>
      )}
    </TableWrap>
  );
}

const TableWrap = styled.table`
  width: 1840px;
  border-collapse: collapse;
  background-color: #fafafa;

  .language-task-status {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    &.active {
      width: 100%;
      color: #e6311a;
    }
  }

  tr {
    &.main {
      td {
        /* padding-left: 10px; */
        /* padding-right: 10px; */

        /* white-space: nowrap; */
        /* overflow: hidden; */
        /* text-overflow: ellipsis; */

        background-color: #fff;

        &:first-of-type {
          border-left: 3px solid #ececec;
          border-top-left-radius: 2px;
        }
        &:last-of-type {
          border-right: 1px solid #ececec;
          border-top-left-radius: 2px;
        }
      }
    }

    td {
      color: #727272;

      & > div {
        display: flex;
        flex-direction: column;
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.25px;
        text-align: left;
      }

      .title {
        font-size: 12px;
        line-height: 19px;
        color: #aaa;
        white-space: break-spaces;
      }
      .content {
        white-space: break-spaces;
      }
    }

    .workers {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;

      .worker {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        padding: 0 16px;

        &.PROGRESS {
          height: 41px;
          background-color: #fffce7;
          align-items: center;
        }
      }
      .role {
        position: relative;
        &.isoutsourcing {
          display: flex;
          flex-direction: column;
          /* padding-top: 16px; */
          .outsource {
            /* position: absolute;
            top: 0px; */
            margin-bottom: 2px;
            padding: 0 3px 0 10px;
            width: 30px;
            height: 13px;
            line-height: 13px;
            text-align: left;
            font-size: 10px;
            color: #9b51e0;
            background: rgba(155, 81, 224, 0.06) url(${IconOutsourcingOnUrl})
              no-repeat left 2px center;
            border-radius: 2px;
            cursor: pointer;
          }
        }
      }
    }
  }
`;
