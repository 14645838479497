import Api from '@src/api/Api';
import { useQuery } from '@tanstack/react-query';
import { DashboardKeys } from '../keys/DashboardKeys';

export function useApi2TaskList(label: string, filterData: any) {
  return useQuery(
    DashboardKeys.Deep2(label, filterData),
    () => {
      return Api.post('/task/listByDay', {
        ...filterData,
        label
      }).then((res) => {
        const v = res.data?.taskList as any[];
        if (!Array.isArray(v)) return [];
        return v;
      });
    },
    {
      // 마감일 오름순 정렬
      select: (data) => {
        if (Array.isArray(data) && data.length > 0) {
          if (data[0]['endDateTime'])
            return data.sort((a: any, b: any) => {
              return a.endDateTime < b.endDateTime
                ? -1
                : a.endDateTime > b.endDateTime
                ? 1
                : 0;
            });
        }
        return data;
      },
      enabled: true,
      // cacheTime: Infinity,
      // staleTime: Infinity,
      retry: 3,
      suspense: true
    }
  );
}
