import React, { useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { Wrap } from './PerformanceStats.style';
import { useGetProjectList } from '@src/hooks/statistics/common/getProjectList';
import { useGetCustomerList } from '@src/hooks/statistics/common/getCustomerList';
import { usePmsFilter } from '@src/hooks/statistics/common/usePmsFilter';
import Footer from '@src/layouts/footer/Footer';
import { useGetFilter } from '@src/hooks/statistics/searchFilter';
import { IcoMenuChevronDown } from '@src/resources/icon';
import TPSelectLangPairPML from '@src/units/select/TPSelect.langPairPML';
import { useLanguageAll } from '@src/hooks/statistics/common/getLanguageAll';
import { renderLanguageByCode } from './utils/render';
import TPButtonClose from '@src/units/button/TPButton.Close';
import { useWorkRetention } from '@src/hooks/statistics/postWorkRetention';
import { TPSelectCheckSearch } from '@src/units/select/TPSelect.checkSearch';
import WorkMonthGraph from './WorkMonthGraph';
import WorkYearGraph from './WorkYearGraph';
import WorkQuarterGraph from './WorkQuarterGraph';
import { TPSelectGraph } from '@src/units/select/TPSelect.graph';
import {
  getRetentionDateType,
  RetentionDateTypeCode
} from '@src/common/config/Code';
import Loading from '../components/Loading';
import WorkTable from './WorkTable';
import { ILanguageTask } from '@src/common/config/IProjectDetail';

// 언어페어 타입
export interface languagePair {
  startLanguage: string;
  destinationLanguage: string;
}

// Post 타입
export interface IWorkRetentionPage {
  languagePairList: languagePair[];
  projectIdList: string[];
  companyIdList: string[];
  baseYear?: number;
}

// 그래프 공통 타입
interface BaseWorkData {
  companyId: number | null;
  companyName: string | null;
  projectId: number | null;
  projectTitle: string | null;
}

export interface IWorkData {
  monthlyRetentionList: IWorkMonthGraphData[];
  yearlyRetentionList: IWorkYearGraphData[];
  quarterlyRetentionList: IWorkQuarterGraphData[];
  latestUpdateDateTime: string;
}

// 월별 그래프 타입
export interface IWorkMonthGraphData extends BaseWorkData {
  year: number;
  jan: number;
  feb: number;
  mar: number;
  apr: number;
  may: number;
  jun: number;
  jul: number;
  aug: number;
  sep: number;
  oct: number;
  nov: number;
  dec: number;
}

export interface IWorkYearGraphData extends BaseWorkData {
  thisYear: number;
  lastYear: number;
  yearBeforeLast: number;
  threeYearsAgo: number;
  fourYearsAgo: number;
}

export interface IWorkQuarterGraphData extends BaseWorkData {
  quarterList: {
    baseYear: number;
    firstQuarter: number;
    secondQuarter: number;
    thirdQuarter: number;
    fourthQuarter: number;
  }[];
}

interface LanguagePairChangeEvent {
  startLanguage: string;
  destinationLanguage: string;
}

const WorkRetentionPage = () => {
  const [projectListFilter, setProjectListFilter] = useState<
    { code: string; label: string }[]
  >([]);
  const [customerListFilter, setCustomerListFilter] = useState<
    { code: string; label: string }[]
  >([]);
  const { filterData, Component: FilterButton } =
    useGetFilter('TASK_RETENTION');
  const { projectList } = useGetProjectList();
  const { customerList } = useGetCustomerList();
  const { data: languageAll } = useLanguageAll();
  const {
    dashboardFilter,
    dashboardFilterProxy,
    languagePairList,
    setLanguagePairList
  } = usePmsFilter(filterData);
  const {
    postMonthFilter,
    postYearFilter,
    postQuarterFilter,
    responseData,
    loading
  } = useWorkRetention('TASK_RETENTION');
  const [retentionDateType, setRetentionDateType] =
    useState<RetentionDateTypeCode>('MONTH');
  const [retentionData, setRetentionData] = useState<IWorkData>();
  const [activeFilter, setActiveFilter] = useState<
    'project' | 'customer' | null
  >(null);
  const [filtersHeight, setFiltersHeight] = useState<number>(0);
  const handlePostData = ({
    languagePairList,
    projectIdList,
    companyIdList,
    baseYear
  }: IWorkRetentionPage) => {
    const result = {
      companyIdList: companyIdList.filter(
        (item) => item !== 'ALL' && item !== '0'
      ),
      projectIdList: projectIdList.filter(
        (item) => item !== 'ALL' && item !== '0'
      ),
      languagePairList,
      ...(retentionDateType === 'MONTH' && {
        baseYear: baseYear || new Date().getFullYear()
      })
    };
    postMonthFilter(result);
  };

  const showSaveButton = useMemo(() => {
    return (
      !dashboardFilter.companyIdList.includes('ALL') ||
      !dashboardFilter.projectIdList.includes('ALL') ||
      languagePairList.length !== 0 ||
      filterData !== null
    );
  }, [dashboardFilter, languagePairList, filterData]);

  const [selectedYear, setSelectedYear] = useState<string>(
    new Date().getFullYear().toString()
  );

  // 조회 기간 변경
  const handleDateTypeChange = (type: RetentionDateTypeCode) => {
    setRetentionDateType(type);
    setSelectedYear(new Date().getFullYear().toString());
    const result = {
      languagePairList,
      projectIdList: dashboardFilter.projectIdList.filter(
        (item) => item !== 'ALL' && item !== '0'
      ),
      companyIdList: dashboardFilter.companyIdList.filter(
        (item) => item !== 'ALL' && item !== '0'
      ),
      ...(type === 'MONTH' && {
        baseYear: selectedYear || new Date().getFullYear().toString()
      })
    };

    switch (type) {
      case 'MONTH':
        postMonthFilter(result as IWorkRetentionPage);
        break;
      case 'YEAR':
        postYearFilter(result as IWorkRetentionPage);
        break;
      case 'QUARTER':
        postQuarterFilter(result as IWorkRetentionPage);
        break;
    }
  };

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= 1999; year--) {
      years.push({
        code: year.toString(),
        label: `${year}`
      });
    }
    return years;
  };

  useEffect(() => {
    if (projectList.length !== projectListFilter.length) {
      setProjectListFilter(
        projectList.filter((item: { code: string }) => item?.code !== '')
      );
    }
  }, [projectList]);

  useEffect(() => {
    if (customerList.length !== customerListFilter.length) {
      setCustomerListFilter(
        customerList.filter((item: { code: string }) => item?.code !== '')
      );
    }
  }, [customerList]);

  useEffect(() => {
    const filtersElement = document.querySelector('.filters');
    if (filtersElement) {
      const totalHeight =
        filtersElement.clientHeight +
        parseInt(window.getComputedStyle(filtersElement).marginTop);
      setFiltersHeight(totalHeight + 56); // 56은 헤더 높이 + 2px는 언어페어 여백
    }
  }, [languagePairList]);

  useEffect(() => {
    if (responseData) {
      setRetentionData(responseData);
    }
  }, [responseData]);

  useEffect(() => {
    const hasProjectFilter =
      dashboardFilter.projectIdList.filter(
        (item) => item !== 'ALL' && item !== '0'
      ).length > 0;

    const hasCustomerFilter =
      dashboardFilter.companyIdList.filter(
        (item) => item !== 'ALL' && item !== '0'
      ).length > 0;

    if (hasProjectFilter) {
      setActiveFilter('project');
    } else if (hasCustomerFilter) {
      setActiveFilter('customer');
    } else {
      setActiveFilter(null);
    }
  }, [dashboardFilter.projectIdList, dashboardFilter.companyIdList]);

  return (
    <section css={Wrap}>
      <div className="filters">
        <div className="fullLookup--title">통계</div>
        <div className="fullLookup--list">
          <div className="name">
            <span>업무 리텐션</span>
          </div>
          <div className="filter--list">
            <div className="left">
              <TPSelectCheckSearch
                label="고객사"
                useOptionLabel
                optionList={customerListFilter.filter(
                  (item: { code: string }) => item?.code !== ''
                )}
                selectOptions={dashboardFilter.companyIdList}
                changeSelect={dashboardFilterProxy.setCustomerList}
                maxSelections={5}
                isDimmed={activeFilter === 'project'}
              />
              <TPSelectCheckSearch
                label="프로젝트"
                useOptionLabel
                optionList={projectListFilter.filter(
                  (item: { code: string }) => item?.code !== ''
                )}
                selectOptions={dashboardFilter.projectIdList}
                changeSelect={dashboardFilterProxy.setProjectList}
                maxSelections={5}
                isDimmed={activeFilter === 'customer'}
              />
              <TPSelectLangPairPML
                badgeContent={languagePairList.length}
                languagePairList={languagePairList}
                languageAll={languageAll}
                onChange={(e: ILanguageTask) => {
                  setLanguagePairList((currentList: ILanguageTask[]) => {
                    const isExisting = currentList.some(
                      (pair) =>
                        pair.startLanguage === e.startLanguage &&
                        pair.destinationLanguage === e.destinationLanguage
                    );
                    if (!isExisting) {
                      return [...currentList, e];
                    }
                    return currentList;
                  });
                }}
              />

              <button
                className={`btn--search ${
                  activeFilter === null ? 'disabled' : ''
                }`}
                onClick={() => {
                  if (activeFilter !== null) {
                    handlePostData({
                      languagePairList,
                      projectIdList: dashboardFilter.projectIdList,
                      companyIdList: dashboardFilter.companyIdList
                    });
                    setRetentionDateType('MONTH');
                    setSelectedYear(new Date().getFullYear().toString());
                  }
                }}
              >
                조회
              </button>
              {showSaveButton && (
                <FilterButton
                  getFilterData={{
                    dashboardFilter: (() => {
                      const { keyword, ...other } = dashboardFilter;
                      return {
                        ...other,
                        projectIdList: dashboardFilter.projectIdList,
                        companyIdList: dashboardFilter.companyIdList
                      };
                    })(),
                    languagePairList: languagePairList.map((pair) => ({
                      startLanguage: pair.startLanguage,
                      destinationLanguage: pair.destinationLanguage
                    }))
                  }}
                />
              )}
            </div>
            {((!dashboardFilter.projectIdList.includes('ALL') &&
              !dashboardFilter.projectIdList.includes('0')) ||
              (!dashboardFilter.companyIdList.includes('ALL') &&
                !dashboardFilter.companyIdList.includes('0')) ||
              languagePairList.length > 0) && (
              <div className="right">
                <button
                  className="btn--reset"
                  onClick={() => {
                    dashboardFilterProxy.clearFilter();
                    setRetentionData(undefined);
                  }}
                >
                  초기화
                </button>
              </div>
            )}
          </div>
          <div className="filter--result">
            {Array.isArray(languagePairList) &&
              languagePairList.length > 0 &&
              languagePairList.map((item: ILanguageTask, i: number) => {
                return (
                  <div className="result--item" key={`language-pair-${i}`}>
                    <div>{renderLanguageByCode(item.startLanguage)}</div>
                    <span className="arrow">
                      <IcoMenuChevronDown
                        fill="#727272"
                        style={{ rotate: '-90deg' }}
                      />
                    </span>
                    <div>{renderLanguageByCode(item.destinationLanguage)}</div>
                    <span className="close">
                      <TPButtonClose
                        onClick={() => {
                          setLanguagePairList((o) => {
                            return o.filter((v) => {
                              return !(
                                v.startLanguage === item.startLanguage &&
                                v.destinationLanguage ===
                                  item.destinationLanguage
                              );
                            });
                          });
                        }}
                      />
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {loading ? (
        <div style={{ width: '100%', height: '500px' }}>
          <Loading />
        </div>
      ) : (
        <div
          className="content--wrap"
          style={{ height: `calc(100vh - ${filtersHeight}px)` }}
        >
          {retentionData ? (
            <div className="content--box">
              <div className="work--retention">
                <div className="content--title">
                  <strong>업무 요청</strong>
                  <p>
                    {format(
                      new Date(retentionData.latestUpdateDateTime),
                      'yyyy.MM.dd HH:mm'
                    )}
                    <span> 기준</span>
                  </p>
                </div>
                <div className="content--graph">
                  <div className="select--wrap">
                    <TPSelectGraph
                      label=""
                      optionList={getRetentionDateType([
                        'YEAR',
                        'MONTH',
                        'QUARTER'
                      ])}
                      selectOption={retentionDateType}
                      changeSelect={handleDateTypeChange}
                    />
                    {retentionDateType === 'MONTH' && (
                      <TPSelectGraph
                        label=""
                        optionList={generateYearOptions()}
                        selectOption={
                          selectedYear || new Date().getFullYear().toString()
                        }
                        changeSelect={(year) => {
                          setSelectedYear(year);
                          handlePostData({
                            languagePairList,
                            projectIdList: dashboardFilter.projectIdList,
                            companyIdList: dashboardFilter.companyIdList,
                            baseYear: year
                          });
                        }}
                      />
                    )}
                  </div>
                  {retentionDateType === 'MONTH' && (
                    <WorkMonthGraph retentionData={retentionData} />
                  )}
                  {retentionDateType === 'YEAR' && (
                    <WorkYearGraph retentionData={retentionData} />
                  )}
                  {retentionDateType === 'QUARTER' && (
                    <WorkQuarterGraph retentionData={retentionData} />
                  )}
                </div>
                <div className="content--table">
                  <WorkTable
                    retentionData={retentionData}
                    retentionDateType={retentionDateType}
                    languagePairList={languagePairList}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="content--none">
              <p>조회된 데이터가 없습니다.</p>
              <p>
                <strong>조회 조건을 설정</strong>한 후 <strong>조회 </strong>
                버튼을 눌러 파트별 퍼포먼스를 확인할 수 있습니다.
              </p>
            </div>
          )}
          <Footer />
        </div>
      )}
    </section>
  );
};

export default WorkRetentionPage;
