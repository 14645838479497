import { Suspense, ComponentProps } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import ErrorBoundaryPage from '@pages/errors/ErrorBoundary';
import Loading from './Loading';

interface AsyncBoundaryProps {
  children: React.ReactNode;
  color?: string;
  lineColor?: string;
  ErrorFallback?: ComponentProps<typeof ErrorBoundary>['FallbackComponent'];
  LoadingFallback?: ComponentProps<typeof Suspense>['fallback'];
  onError?: ComponentProps<typeof ErrorBoundary>['onError'];
  resetKeys?: ComponentProps<typeof ErrorBoundary>['resetKeys'];
}

interface LoadingProps {
  color?: string;
  lineColor?: string;
}

function DefaultErrorFallback({ error }: FallbackProps) {
  return <ErrorBoundaryPage />;
}

function DefaultLoadingFallback({ color, lineColor }: LoadingProps) {
  return <Loading color={color} lineColor={lineColor} />;
}

function AsyncBoundary({
  children,
  color,
  lineColor,
  ErrorFallback = DefaultErrorFallback,
  LoadingFallback = (
    <DefaultLoadingFallback color={color} lineColor={lineColor} />
  ),
  onError,
  resetKeys
}: AsyncBoundaryProps) {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={onError}
      resetKeys={resetKeys}
    >
      <Suspense fallback={LoadingFallback}>{children}</Suspense>
    </ErrorBoundary>
  );
}

export default AsyncBoundary;
