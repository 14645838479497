import { CleaningServices } from '@mui/icons-material';
import ErrorPage from '@pages/errors/Errors.page';
import Logger from '@src/api/Logger';
import {
  isRouteErrorResponse,
  useLocation,
  useNavigate,
  useRouteError
} from 'react-router-dom';

const ErrorBoundaryPage = () => {
  const navigate = useNavigate();
  const error = useRouteError();
  const location = useLocation();

  const fallBack = () => {
    navigate('/', { replace: true });
  };

  let errorInfo = {
    status: '403',
    message: '권한 오류!',
    info: '접근 권한이 없습니다.',
    cacheClean: false,
    callback: fallBack
  };

  const errorObject = error as {
    data: string;
    error: { message?: string; stack?: string };
    status: number;
    internal: boolean;
    statusText: string;
  };

  if (isRouteErrorResponse(error)) {
    switch (error.status) {
      case 403:
        errorInfo = {
          status: '403',
          message: '권한 오류!',
          info: '접근 권한이 없습니다.',
          cacheClean: false,
          callback: fallBack
        };
        break;
      case 404:
        errorInfo = {
          status: '404',
          message: '존재하지 않는 페이지!',
          info: '해당 페이지가 없습니다.',
          cacheClean: false,
          callback: fallBack
        };
        break;
      default:
        break;
    }
  }

  const displayErrorMessage = `status : ${
    errorObject.status ? errorObject.status.toString() : 'XOX'
  }, ErrorMessage : ${
    errorObject.error ? errorObject.error.message : errorObject
  }, ErrorHref: ${window.location.href}`;

  // localhost 에러는 슬랙으로 보내지 않는다.
  if (window.location.hostname !== 'localhost') {
    Logger.error(displayErrorMessage.toString(), 'COMPONENT');
  }

  return <ErrorPage {...errorInfo} />;
};

export default ErrorBoundaryPage;
