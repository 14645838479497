//lib
import { Backdrop, Box } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
//etc
import {
  LANGUAGE,
  getContractItem,
  getDateType,
  getStatus,
  getTaskType,
  isPublished
} from '@src/common/config/Code';
import ModalType from '@src/common/config/ModalType';
import { TPAvatarBasic } from '@src/units/avatar/TPAvatar.Basic';
import { TPChipBasic } from '@src/units/chip/TPChip.Basic';
import { TPInputSearchNew } from '@src/units/input/TPInput.searchNew';
import { TPSelectCheck } from '@src/units/select/TPSelect.check';
import { TPSelectCheckManager } from '@src/units/select/TPSelect.checkPM';
import { TPSelectOne } from '@src/units/select/TPSelect.one';
import { DashBoard } from '../components/DashBoard';
import { projectColumns } from './Project.columns';
import { ProjectWrap } from './Project.style';

//hooks
import { useRangeCalendar } from '@hooks/modal/useRangeCalendar';
import { usePagePermission } from '@src/hooks/auth/useAuth';
import { useMyInfo } from '@src/hooks/myInfo/getMyInfo';
import { useGetProjectWorkerList } from '@src/hooks/projectDetail/getWorkerList';
import {
  initialDataMap,
  useGetProjectDetailList
} from '@src/hooks/projectDetail/postList';
import { useGetFilter } from '@src/hooks/searchFilter';
import { useUpsertTask } from '@src/hooks/task/post';
import { usePutStatus } from '@src/hooks/task/putStatus';
import Footer from '@src/layouts/footer/Footer';
import TPAvatarGroupNew from '@src/units/avatar/TPAvatar.GroupNew';
import TPButtonClose from '@src/units/button/TPButton.Close';
import TPButtonReset from '@src/units/button/TPButton.Reset';
import { createPortal } from 'react-dom';
import ProjectTable from './ProjectTable';
import { useAlert } from '@src/hooks/modal/useAlert';
import usePostExcel from '@src/hooks/project/postExcel';
import Loading from '../components/Loading';

/**
 * 프로젝트 상세 페이지
 * route : /project/:id
 * auth  : PML, PM
 * @returns
 */
function ProjectPage() {
  const { myInfo, isLoading } = useMyInfo();
  const canView = usePagePermission(myInfo);
  const { openHandle: alertHandle } = useAlert();
  const navigate = useNavigate();
  const { id } = useParams();
  sessionStorage.setItem('projectId', id ?? ''); // sessionStorage에 projectId 저장

  const refRoot = useRef<HTMLDivElement>(null);
  const ref1 = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);
  //#region hooks call
  //todo 필터 타입 지정 필요
  const { filterData, Component: FilterButton } =
    useGetFilter('PROJECT_DETAIL');
  const { putStatus } = usePutStatus();
  const { deleteTask, isLoading: isDeletePending } = useUpsertTask();
  const { taskManagerList } = useGetProjectWorkerList(id ?? '');
  const {
    projectDetail,
    taskList,
    dashBoardItemList,
    taskTitle,
    taskFilter,
    dateType,
    dateFilter,
    isFilter,
    isSort,
    setState,
    dDay,
    shouldDisplayDDay,
    isExcel
  } = useGetProjectDetailList({ projectId: id ?? '', filterData });

  const {
    companyName,
    companyIconImgUrl,
    projectTitle,
    totalGrossAmount,
    totalNetAmount,
    startDateTime,
    endDateTime,
    mainProjectManagerName,
    mainProjectManagerStatus,
    subProjectManagerName,
    subProjectManagerStatus,
    contractType,
    projectAssignerList
  } = projectDetail;
  const {
    openHandle,
    Component: Calendar,
    open: calendarOpened
  } = useRangeCalendar();
  //#endregion

  //#region set state
  const [searchValue, setSearchValue] = useState('');
  const {
    setTasker,
    setTaskType,
    setTaskStatus,
    setTaskTitle,
    setDateType,
    setDateFilter,
    setResetState,
    setIsSort,
    setStartLanguageList,
    setDestinationLanguageList
  } = setState;
  //#endregion
  const { downloadData } = usePostExcel();
  const requiredStatuses = ['NEW', 'COMPLETE_ASSIGN', 'PROGRESS', 'COMPLETE'];

  const showSaveButton = useMemo(() => {
    return (
      !taskFilter.startLanguageList.includes('ALL') ||
      !taskFilter.destinationLanguageList.includes('ALL') ||
      !(
        taskFilter.taskStatus.length === 4 &&
        requiredStatuses.every((status) =>
          taskFilter.taskStatus.includes(status)
        )
      ) ||
      !taskFilter.taskType.includes('ALL') ||
      !taskFilter.workUserIdList.includes('0') ||
      dateType !== 'ALL' ||
      dateFilter.startDateTime !== undefined ||
      dateFilter.endDateTime !== undefined ||
      filterData !== null
    );
  }, [taskFilter, dateType, filterData, dateFilter]);

  const showResetButton = useMemo(() => {
    return (
      !taskFilter.startLanguageList.includes('ALL') ||
      !taskFilter.destinationLanguageList.includes('ALL') ||
      !(
        taskFilter.taskStatus.length === 4 &&
        requiredStatuses.every((status) =>
          taskFilter.taskStatus.includes(status)
        )
      ) ||
      !taskFilter.taskType.includes('ALL') ||
      !taskFilter.workUserIdList.includes('0') ||
      dateType !== 'ALL' ||
      dateFilter.startDateTime !== undefined ||
      dateFilter.endDateTime !== undefined
    );
  }, [taskFilter, dateType, dateFilter]);

  const resetState = () => {
    setResetState();
    setSearchValue('');
  };

  const clickJobRegister = () => {
    sessionStorage.setItem('projectId', id ?? '');
    navigate(`/project/${id}/register/${id}`);
  };
  const clickRowData = (rowData: any) => {
    const { taskId } = rowData;
    navigate(`/project/${id}/${ModalType.JOBDETAIL_PAGE}/${taskId}`);
  };
  const activeValid = () => {
    let mainPM = mainProjectManagerName;
    let subPM = subProjectManagerName;

    if (mainProjectManagerStatus === 'INACTIVE') {
      mainPM = `${mainProjectManagerName} (비활성화)`;
    } else if (mainProjectManagerStatus === 'LEAVE') {
      mainPM = '삭제된 사용자';
    } else if (mainProjectManagerName === null) {
      mainPM = '없음';
    }
    if (subProjectManagerStatus === 'INACTIVE') {
      subPM = `${subProjectManagerName} (비활성화)`;
    } else if (subProjectManagerStatus === 'LEAVE') {
      subPM = '삭제된 사용자';
    } else if (subProjectManagerName === null) {
      subPM = '없음';
    }
    return `${mainPM} / ${subPM}`;
  };

  const getProjectAssignerListElement = () => {
    const list = projectDetail?.projectAssignerList;
    if (Array.isArray(list) && list.length > 0) {
      return <TPAvatarGroupNew userList={list} />;
    } else {
      return '없음';
    }
  };

  const renderDateRangeElement = () => {
    if (
      dateType !== 'ALL' &&
      dateFilter.startDateTime &&
      dateFilter.endDateTime
    ) {
      const startLabel = format(
        new Date(dateFilter.startDateTime),
        'yyyy.MM.dd'
      );
      const endLabel = format(new Date(dateFilter.endDateTime), 'yyyy.MM.dd');

      return (
        <div className="calendar-range">
          <div>{getDateType([dateType as any])?.[0]?.label ?? ''}</div>
          <div>
            {startLabel === endLabel
              ? startLabel
              : `${startLabel} - ${endLabel}`}
          </div>
          <TPButtonClose
            onClick={() => {
              // 날짜 필터링 초기화
              setDateType(initialDataMap.dateType);
              setDateFilter(initialDataMap.dateFilter);
            }}
          />
        </div>
      );
    }

    return null;
  };

  const onResize = () => {
    if (ref1.current && ref2.current) {
      const rect = ref1.current.getBoundingClientRect();
      ref2.current.style.paddingTop = `${70 + rect.top + rect.height}px`;
      ref2.current.style.paddingBottom = `80px`;
    }
  };

  const xlsxDownload = () => {
    alertHandle({
      title: '작업 데이터 다운로드',
      text: '데이터 양에 따라 다운로드에 <br />시간이 걸릴 수 있습니다.',
      cancelLabel: '취소',
      ok: () => {
        downloadData(isExcel);
      }
    });
  };

  useLayoutEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  });

  if (!isLoading && !canView) throw new Error('접근 권한이 없습니다.');

  return (
    <>
      <section
        css={ProjectWrap}
        ref={refRoot}
        style={{
          display: 'block'
        }}
      >
        {calendarOpened &&
          createPortal(
            <Backdrop open sx={{ zIndex: 9999 }}>
              <Calendar />
            </Backdrop>,
            document.documentElement
          )}
        <Box
          ref={ref1}
          sx={{
            position: 'fixed',
            top: '56px',
            left: '0',
            right: '0',
            bgcolor: '#fff',
            margin: '0 -40px',
            padding: '0 80px',
            zIndex: 1051
          }}
        >
          <Box
            sx={{
              pt: '15px',
              pb: '26px'
            }}
          >
            <div className="info-top">
              <TPAvatarBasic
                alt="대체 이미지"
                src={companyIconImgUrl}
                size="large"
                sx={{
                  img: {
                    objectFit: 'contain'
                  }
                }}
              />
              <div className="info">
                <div className="sub-title">{companyName}</div>
                <div className="title">{projectTitle}</div>
              </div>
            </div>
            <dl className="project--dl--info">
              <div>
                <dt>Gross</dt>
                <dd>
                  {totalGrossAmount ? totalGrossAmount.toLocaleString() : 0}
                </dd>
              </div>
              <div>
                <dt>Net</dt>
                <dd>{totalNetAmount ? totalNetAmount.toLocaleString() : 0}</dd>
              </div>
              <div>
                <dt>계약 기간</dt>
                <dd>
                  {startDateTime && endDateTime
                    ? `${format(
                        new Date(startDateTime),
                        'yy. MM. dd'
                      )} ~ ${format(new Date(endDateTime), 'yy. MM. dd')}`
                    : ''}
                </dd>
                <div className="dDay">
                  {shouldDisplayDDay ? (
                    <TPChipBasic
                      label={dDay ? `D-${dDay}` : 'D-Day'}
                      size="small"
                      state="WAIT"
                      variant="filled"
                    />
                  ) : null}
                </div>
              </div>
              <div>
                <dt>담당 PM</dt>
                <dd>{activeValid()}</dd>
              </div>
              <div>
                <dt>담당자</dt>
                <dd>{getProjectAssignerListElement()}</dd>
              </div>
              <div>
                <dt>작업 견적</dt>
                <dd>{getContractItem(contractType)?.label}</dd>
              </div>
            </dl>
          </Box>
          <Box display={'flex'} gap={'8px'} justifyContent={'space-between'}>
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'flex-start'}
              flexWrap={'wrap'}
              columnGap={'8px'}
            >
              <TPSelectCheck
                label="상태"
                useOptionLabel
                optionList={getStatus([
                  'ALL',
                  'NEW',
                  'COMPLETE_ASSIGN',
                  'PROGRESS',
                  'STOP',
                  'COMPLETE',
                  'DELIVERY_COMPLETE'
                ])}
                selectOptions={taskFilter.taskStatus ?? []}
                changeSelect={setTaskStatus}
              />
              <TPSelectCheck
                label="유형"
                optionList={getTaskType([
                  'ALL',
                  'TRANSLATION',
                  'POLISHING',
                  'POST_EDITING',
                  'LQA'
                ])}
                selectOptions={taskFilter.taskType ?? []}
                changeSelect={setTaskType}
              />
              <TPSelectCheck
                label="출발어"
                optionList={LANGUAGE.filter((item: any) => item?.code !== '')}
                useOptionLabel
                selectOptions={taskFilter.startLanguageList ?? []}
                changeSelect={setStartLanguageList}
              />
              <TPSelectCheck
                label="도착어"
                optionList={LANGUAGE.filter((item: any) => item?.code !== '')}
                useOptionLabel
                selectOptions={taskFilter.destinationLanguageList ?? []}
                changeSelect={setDestinationLanguageList}
              />
              <TPSelectCheckManager
                label="작업자"
                PMList={taskManagerList}
                showAvatar={true}
                selectOptions={taskFilter.workUserIdList ?? []}
                changeSelect={setTasker}
              />
              <TPSelectOne
                label="날짜"
                optionList={getDateType([
                  'ALL',
                  'HOPE_SUPPLY_DATE',
                  'START_DATE',
                  'END_DATE'
                ])}
                selectOption={dateType}
                changeSelect={(type) => {
                  if (type === 'ALL') {
                    setDateType(type);
                    setDateFilter(initialDataMap.dateFilter);
                  } else {
                    openHandle(
                      'project',
                      initialDataMap.dateFilter,
                      (range) => {
                        setDateType(type);
                        setDateFilter(range);
                      }
                    );
                  }
                }}
              />

              {showSaveButton && (
                <FilterButton
                  getFilterData={{
                    taskTitle,
                    taskFilter,
                    dateType,
                    dateFilter
                  }}
                />
              )}
            </Box>
            <div style={{ display: 'flex', gap: '20px' }}>
              <TPInputSearchNew
                value={searchValue}
                placeholder="검색"
                onChange={(e) => setSearchValue(e.target.value)}
                clickSearch={() => setTaskTitle(searchValue)}
              />
              {showResetButton && <TPButtonReset onClick={resetState} border />}
            </div>
          </Box>
          {renderDateRangeElement()}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'stretch',
              mx: '-40px',
              bgcolor: '#f9f9f9',
              'div:first-of-type': {
                paddingLeft: '20px'
              },
              'div:last-of-type': {
                paddingRight: '40px'
              }
            }}
          >
            <DashBoard list={dashBoardItemList} />
            <Box
              sx={{
                py: '5.5px',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                flexGrow: 1,
                borderTop: '1px solid #ececec',
                borderBottom: '1px solid #ececec'
              }}
            >
              {/* {isPublished && (
                <button
                  className="file-manager"
                  onClick={() => {
                    navigate('/taskManager');
                  }}
                  style={{
                    wordBreak: 'keep-all'
                  }}
                >
                  작업 파일 관리
                </button>
              )} */}
              <button
                className="normal-btn"
                onClick={() => {
                  xlsxDownload();
                }}
              >
                데이터 다운로드
              </button>
              {mainProjectManagerName &&
              subProjectManagerName &&
              Array.isArray(projectAssignerList) &&
              projectAssignerList.length > 0 ? (
                <button
                  style={{
                    wordBreak: 'keep-all'
                  }}
                  className="regist"
                  onClick={clickJobRegister}
                  // disabled={!(mainProjectManagerName || subProjectManagerName)}
                >
                  작업 등록
                </button>
              ) : null}
            </Box>
          </Box>
        </Box>

        <div className="content" ref={ref2}>
          <ProjectTable
            columns={projectColumns(
              id,
              putStatus,
              deleteTask,
              isSort,
              setIsSort
            )}
            dataList={taskList}
            // clickRowData={clickRowData}
            noRowsMessage={`${isFilter ? '검색된' : '배정된'} 작업이 없습니다.`}
            clickColData={{
              taskTitle: clickRowData
            }}
            // counter={projectDetail}
          />
          {/* <DataTable
          columns={projectColumns(id, putStatus, deleteTask, isSort, setIsSort)}
          dataList={taskList}
          // clickRowData={clickRowData}
          noRowsMessage={`${isFilter ? '검색된' : '배정된'} 작업이 없습니다.`}
          clickColData={{
            taskTitle: clickRowData
          }}
          counter={projectDetail}
        ></DataTable> */}
        </div>
        <Footer />

        <Outlet />
      </section>
      {isDeletePending && <Loading position="fixed" />}
    </>
  );
}

export default ProjectPage;
