import React, { useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { Wrap } from './PerformanceStats.style';
import { TPButtonActionCalendar } from '@src/units/button/TPButton.ActionCalendar';
import { useRangeCalendar } from '@src/hooks/modal/useRangeCalendar';
import { useGetProjectList } from '@src/hooks/statistics/common/getProjectList';
import { usePmsFilter } from '@src/hooks/statistics/common/usePmsFilter';
import Footer from '@src/layouts/footer/Footer';
import { useGetWorkerList } from '@src/hooks/statistics/common/getWorkerList';
import { useGetFilter } from '@src/hooks/statistics/searchFilter';
import { IcoMenuChevronDown, IconBasicUserUrl } from '@src/resources/icon';
import { getWorkloadType, LANGUAGE } from '@src/common/config/Code';
import TPSelectLangPairPML from '@src/units/select/TPSelect.langPairPML';
import { useLanguageAll } from '@src/hooks/statistics/common/getLanguageAll';
import { renderLanguageByCode } from './utils/render';
import TPButtonClose from '@src/units/button/TPButton.Close';
import { TPSelectCheck } from '@src/units/select/TPSelect.check';
import { useWorkerResource } from '@src/hooks/statistics/postWorkerResource';
import Loading from '../components/Loading';

interface languagePair {
  startLanguage: string;
  destinationLanguage: string;
}

interface IWorkerResourcePage {
  languagePairList: languagePair[];
  startDateTime: Date;
  endDateTime: Date;
  workloadType: string[];
}

interface resourceList {
  userId: number;
  userName: string;
  userImgUrl: string;
  startLanguage: string;
  destinationLanguage: string;
  grossAmount: number;
  workLoadStatus: string;
  workLoadPercentage: number;
}

interface IWorkerResourceData {
  date: Date;
  resourceData: resourceList[];
}

const WorkerResourcePage = () => {
  const [projectListFilter, setProjectListFilter] = useState<
    { code: string; label: string }[]
  >([]);
  const { filterData, Component: FilterButton } =
    useGetFilter('WORKER_RESOURCE');
  const { projectList } = useGetProjectList();
  const { data: languageAll } = useLanguageAll();
  const {
    dashboardFilter,
    dashboardFilterProxy,
    languagePairList,
    setLanguagePairList,
    searchDateFilter,
    setSearchDateFilter,
    dateFilter,
    setDateFilter
  } = usePmsFilter(filterData);
  const { postFilter, responseData, loading } =
    useWorkerResource('WORKER_RESOURCE');
  const [resourceData, setResourceData] = useState<IWorkerResourceData>();
  const [resourceDetail, setResourceDetail] = useState<resourceList[]>();
  const { openHandle, Component: Calendar } = useRangeCalendar({
    dateType: 'searchDateRange'
  });
  const [filtersHeight, setFiltersHeight] = useState<number>(0);
  const handlePostData = ({
    languagePairList,
    startDateTime,
    endDateTime,
    workloadType
  }: IWorkerResourcePage) => {
    const startTime = format(new Date(startDateTime), 'yyyy-MM-dd');
    const endTime = format(new Date(endDateTime), 'yyyy-MM-dd');
    const allWorkloadTypes = ['BUFFER', 'SMOOTH', 'OPTIMAL', 'OVERLOAD'];
    const filteredWorkloadType = workloadType.includes('ALL')
      ? allWorkloadTypes
      : workloadType.filter((item) => item !== 'ALL');
    const result = {
      languagePairList,
      startDateTime: startTime,
      endDateTime: endTime,
      workLoadStatusList: filteredWorkloadType
    };
    postFilter(result);
  };

  const showSaveButton = useMemo(() => {
    return (
      !dashboardFilter.workloadType.includes('ALL') ||
      languagePairList.length !== 0 ||
      format(new Date(searchDateFilter.startDateTime), 'yyyy.MM.dd') !==
        format(new Date(), 'yyyy.MM.dd') ||
      format(new Date(searchDateFilter.endDateTime), 'yyyy.MM.dd') !==
        format(new Date(), 'yyyy.MM.dd') ||
      filterData !== null
    );
  }, [dashboardFilter, languagePairList, searchDateFilter, filterData]);

  useEffect(() => {
    if (projectList.length !== projectListFilter.length) {
      setProjectListFilter(
        projectList.filter((item: { code: string }) => item?.code !== '')
      );
    }
  }, [projectList]);

  useEffect(() => {
    const filtersElement = document.querySelector('.filters');
    if (filtersElement) {
      const totalHeight =
        filtersElement.clientHeight +
        parseInt(window.getComputedStyle(filtersElement).marginTop);
      setFiltersHeight(totalHeight + 56); // 56은 헤더 높이 + 2px는 언어페어 여백
    }
  }, [languagePairList]);

  useEffect(() => {
    if (responseData) {
      const typedResponse = responseData as IWorkerResourceData;
      setResourceData(typedResponse);
      setResourceDetail(typedResponse.resourceData);
    }
  }, [responseData]);

  return (
    <section css={Wrap}>
      <div className="filters">
        <div className="fullLookup--title">통계</div>
        <div className="fullLookup--list">
          <div className="name">
            <span>작업자 리소스</span>
          </div>
          <div className="filter--list">
            <div className="left">
              <TPSelectLangPairPML
                badgeContent={languagePairList.length}
                languagePairList={languagePairList}
                languageAll={languageAll}
                onChange={(e: any) => {
                  setLanguagePairList((currentList) => {
                    const isExisting = currentList.some(
                      (pair) =>
                        pair.startLanguage === e.startLanguage &&
                        pair.destinationLanguage === e.destinationLanguage
                    );
                    if (!isExisting) {
                      return [...currentList, e];
                    }
                    return currentList;
                  });
                }}
              />
              <TPSelectCheck
                label="업무 부하 상태"
                useOptionLabel
                optionList={getWorkloadType([
                  'ALL',
                  'BUFFER',
                  'SMOOTH',
                  'OPTIMAL',
                  'OVERLOAD'
                ])}
                selectOptions={dashboardFilter.workloadType}
                changeSelect={dashboardFilterProxy.setworkloadType}
              />
              <div
                onClick={() => {
                  openHandle('search', searchDateFilter, (newDateFilter) => {
                    setSearchDateFilter(newDateFilter);
                    setDateFilter({
                      startDateTime: new Date(newDateFilter.startDateTime),
                      endDateTime: new Date(newDateFilter.endDateTime)
                    });
                  });
                }}
              >
                <TPButtonActionCalendar
                  calendarType={'normal'}
                  content={
                    searchDateFilter.startDateTime &&
                    searchDateFilter.endDateTime
                      ? `기간 ${format(
                          new Date(searchDateFilter.startDateTime),
                          'yyyy.MM.dd'
                        )}${
                          new Date(searchDateFilter.startDateTime).getTime() ===
                          new Date(searchDateFilter.endDateTime).getTime()
                            ? ''
                            : ' - ' +
                              format(
                                new Date(searchDateFilter.endDateTime),
                                'yyyy.MM.dd'
                              )
                        }`
                      : ''
                  }
                />
              </div>

              <button
                className={`btn--search ${
                  searchDateFilter.startDateTime &&
                  searchDateFilter.endDateTime &&
                  languagePairList.length > 0
                    ? ''
                    : 'disabled'
                }`}
                onClick={() => {
                  if (
                    searchDateFilter.startDateTime &&
                    searchDateFilter.endDateTime &&
                    languagePairList.length > 0
                  ) {
                    handlePostData({
                      languagePairList,
                      startDateTime: searchDateFilter.startDateTime,
                      endDateTime: searchDateFilter.endDateTime,
                      workloadType: dashboardFilter.workloadType
                    });
                  }
                }}
              >
                조회
              </button>
              {showSaveButton && (
                <FilterButton
                  getFilterData={{
                    dashboardFilter: (() => {
                      const { keyword, ...other } = dashboardFilter;
                      return {
                        ...other,
                        workloadType: dashboardFilter.workloadType
                      };
                    })(),
                    languagePairList: languagePairList.map((pair) => ({
                      startLanguage: pair.startLanguage,
                      destinationLanguage: pair.destinationLanguage
                    })),
                    searchDateFilter: searchDateFilter,
                    dateFilter: dateFilter
                  }}
                />
              )}
            </div>
            {(!dashboardFilter.workloadType.includes('ALL') ||
              format(new Date(searchDateFilter.startDateTime), 'yyyy.MM.dd') !==
                format(new Date(), 'yyyy.MM.dd') ||
              format(new Date(searchDateFilter.endDateTime), 'yyyy.MM.dd') !==
                format(new Date(), 'yyyy.MM.dd') ||
              languagePairList.length > 0) && (
              <div className="right">
                <button
                  className="btn--reset"
                  onClick={() => {
                    dashboardFilterProxy.clearFilter();
                    setResourceData(undefined);
                  }}
                >
                  초기화
                </button>
              </div>
            )}
          </div>
          <div className="filter--result">
            {Array.isArray(languagePairList) &&
              languagePairList.length > 0 &&
              languagePairList.map((item: any, i: number) => {
                return (
                  <div className="result--item" key={`language-pair-${i}`}>
                    <div>{renderLanguageByCode(item.startLanguage)}</div>
                    <span className="arrow">
                      <IcoMenuChevronDown
                        fill="#727272"
                        style={{ rotate: '-90deg' }}
                      />
                    </span>
                    <div>{renderLanguageByCode(item.destinationLanguage)}</div>
                    <span className="close">
                      <TPButtonClose
                        onClick={() => {
                          setLanguagePairList((o) => {
                            return o.filter((v) => {
                              return !(
                                v.startLanguage === item.startLanguage &&
                                v.destinationLanguage ===
                                  item.destinationLanguage
                              );
                            });
                          });
                        }}
                      />
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {loading ? (
        <div style={{ width: '100%', height: '500px' }}>
          <Loading />
        </div>
      ) : (
        <div
          className="content--wrap"
          style={{ height: `calc(100vh - ${filtersHeight}px)` }}
        >
          {resourceData ? (
            <div className="content--box">
              <div className="resource--wrap">
                <p className="worker--resource--date">
                  {format(new Date(resourceData.date), 'yyyy.MM.dd HH:mm')}
                  <span>기준</span>
                </p>
                <div className="resource--list">
                  <div className="table--wrap">
                    <table>
                      <colgroup>
                        <col width="200px" />
                        <col width="15%" />
                        <col width="15%" />
                        <col width="12%" />
                        <col width="645px" />
                        <col width="150px" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th scope="col">작업자</th>
                          <th scope="col">출발어</th>
                          <th scope="col">도착어</th>
                          <th scope="col">업무 부하 상태</th>
                          <th scope="col">업무 부하 수치</th>
                          <th scope="col">작업 분량</th>
                        </tr>
                      </thead>
                      <tbody>
                        {resourceDetail?.length &&
                        resourceDetail?.length > 0 ? (
                          resourceDetail?.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <div className="user--info">
                                  <span className="img">
                                    <img
                                      src={item.userImgUrl}
                                      alt={item.userName}
                                      onError={(
                                        e: React.SyntheticEvent<
                                          HTMLImageElement,
                                          Event
                                        >
                                      ) => {
                                        const target =
                                          e.target as HTMLImageElement;
                                        target.src = IconBasicUserUrl;
                                      }}
                                    />
                                  </span>
                                  <span className="name">{item.userName}</span>
                                </div>
                              </td>
                              <td>
                                {LANGUAGE.filter(
                                  (v) => v.code === item.startLanguage
                                ).map((lang) => lang.label)}
                              </td>
                              <td>
                                {LANGUAGE.filter(
                                  (v) => v.code === item.destinationLanguage
                                ).map((lang) => lang.label)}
                              </td>
                              <td>
                                {item.workLoadStatus &&
                                [
                                  'BUFFER',
                                  'SMOOTH',
                                  'OPTIMAL',
                                  'OVERLOAD'
                                ].includes(item.workLoadStatus)
                                  ? getWorkloadType([
                                      item.workLoadStatus as
                                        | 'BUFFER'
                                        | 'SMOOTH'
                                        | 'OPTIMAL'
                                        | 'OVERLOAD'
                                    ]).map((lang, index) => (
                                      <strong
                                        className={`${item.workLoadStatus}`}
                                        key={`lang.code` + index}
                                      >
                                        {lang.label}
                                      </strong>
                                    ))
                                  : '알 수 없음'}
                              </td>
                              <td>
                                <div className="workload--percentage">
                                  <span
                                    className={`bar ${item.workLoadStatus}`}
                                    style={{
                                      width: `${
                                        item.workLoadStatus === 'BUFFER'
                                          ? `${item.workLoadPercentage}px`
                                          : item.workLoadStatus === 'SMOOTH'
                                          ? `${
                                              50 +
                                              (item.workLoadPercentage - 50) * 5
                                            }px`
                                          : item.workLoadStatus === 'OPTIMAL'
                                          ? `${
                                              200 +
                                              (item.workLoadPercentage - 80) * 5
                                            }px`
                                          : `${
                                              300 +
                                              (item.workLoadPercentage - 100) *
                                                5
                                            }px`
                                      }`
                                    }}
                                  ></span>
                                  <strong
                                    className={`bar--value ${item.workLoadStatus}`}
                                  >
                                    {item.workLoadPercentage.toLocaleString()}
                                    <span>%</span>
                                  </strong>
                                </div>
                              </td>
                              <td>{item.grossAmount.toLocaleString()}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={6}>
                              <div className="content--none">
                                <p>조회된 데이터가 없습니다.</p>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="content--none">
              <p>조회된 데이터가 없습니다.</p>
              <p>
                <strong>조회 조건을 설정</strong>한 후 <strong>조회 </strong>
                버튼을 눌러 파트별 퍼포먼스를 확인할 수 있습니다.
              </p>
            </div>
          )}
          <Footer />
        </div>
      )}
      <Calendar />
    </section>
  );
};

export default WorkerResourcePage;
