import { cx } from '@emotion/css';
import { Box } from '@mui/material';
import {
  compareWorkerType,
  getCategoryLabel,
  getLanguageLabelByCode,
  getTaskStatusLabelColor,
  getWorkerTypeLabel
} from '@src/common/config/Code';
import {
  MyWorkRowStatusButton,
  renderDateTime,
  renderGrossInfo,
  renderLanguageSubTaskStatus,
  renderNetInfo,
  renderRowStatus
} from '@src/pages/dashboard/utils/render';
import {
  IcoMenuChevronDown,
  IconMessageOff,
  IconMessageOn
} from '@src/resources/icon';
import TPAvatarGroupNew from '@src/units/avatar/TPAvatar.GroupNew';
import { useQueryClient } from '@tanstack/react-query';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Td } from './Td';
import { useToast } from '@src/hooks/modal/useToast';
import { Avatar } from '@src/units/sobra/Avatar';
import { IconFileTitleTrue, IconFileTitleFalse } from '@src/resources/icon';
import { formatWorkerList } from '@src/pages/dashboard/utils/formatWorkerList';
import { languageTaskKeys } from '@src/hooks/queryKeyFactory';
import { SimpleTooltip } from '@src/units/tooltip/SimpleTooltip';
import { DashboardKeys } from '@src/pages/dashboard/keys/DashboardKeys';
export const TBody = ({
  i,
  item,
  myInfo,
  refetchLanguageTaskList,
  ...props
}: any) => {
  if (item.languageTaskTitle === 'adsf') console.debug(item);

  const { openHandle: toastOpen } = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const navigateToDetailPage = (rowData: any) => {
    const { languageTaskId } = rowData;
    navigate(`/tasktab/detail/${languageTaskId}`);
  };

  const navigateToAssignPage = (rowData: any) => {
    const { languageTaskId } = rowData;
    navigate(`/tasktab/assign/${languageTaskId}`);
  };

  return (
    <tbody key={i}>
      {i !== 0 && <tr style={{ height: 10 }}></tr>}
      <tr
        className="main"
        style={
          Array.isArray(item?.languageSubTaskList) &&
          item.languageSubTaskList.length === 0
            ? {
                borderBottom: '1px solid #ececec'
              }
            : { backgroundColor: 'red' }
        }
      >
        <td
          style={{
            borderLeft: '3px solid',
            borderLeftColor: getTaskStatusLabelColor(item.languageTaskStatus),
            width: 130,
            height: 61,
            paddingLeft: 14,
            paddingRight: 14
          }}
        >
          {item.includeSubTaskByPm ? (
            <SimpleTooltip
              message="작업자가 배정되지 않은 부작업이 있습니다."
              position="top"
            >
              <div
                className="language-task-status active"
                data-language-task-status={item.languageTaskStatus}
              >
                {renderRowStatus(item.languageTaskStatus)}
              </div>
            </SimpleTooltip>
          ) : (
            <div
              className="language-task-status"
              data-language-task-status={item.languageTaskStatus}
            >
              {renderRowStatus(item.languageTaskStatus)}
            </div>
          )}
        </td>
        <Td
          width={574}
          style={{
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-all'
          }}
          title={
            <div className="project-title">
              {item.companyName}
              <IcoMenuChevronDown fill="#D9D9D9" style={{ rotate: '-90deg' }} />
              {item.projectTitle}
            </div>
          }
          content={
            typeof item.languageTaskTitle === 'string' ? (
              <div
                onClick={() => navigateToDetailPage(item)}
                className="task-title"
                style={{
                  whiteSpace: 'break-spaces',
                  cursor: 'pointer',
                  minHeight: '1rem'
                }}
              >
                {item.fileRegisterStatus ? (
                  <IconFileTitleTrue className="title-fileuse" />
                ) : (
                  <IconFileTitleFalse className="title-fileuse" />
                )}
                {item.languageTaskTitle}
              </div>
            ) : (
              <div className="task-title">{`{languageTaskTitle} 없음`}</div>
            )
          }
        />
        <Td title="유형" content={getCategoryLabel(item.taskType)} />
        <Td
          title="담당 PM"
          content={
            <TPAvatarGroupNew
              userList={
                [item.mainProjectManager, item.subProjectManager] as any[]
              }
            />
          }
        />
        <Td
          title="출발어"
          content={getLanguageLabelByCode(item.startLanguage)}
        />
        <Td
          title="도착어"
          content={getLanguageLabelByCode(item.destinationLanguage)}
        />
        <Td
          title="시작일"
          content={renderDateTime(item.startDateTime, false)}
        />
        <Td
          title="마감일"
          content={renderDateTime(
            item.endDateTime,
            !['COMPLETE', 'DELIVERY_COMPLETE'].includes(item.languageTaskStatus)
          )}
        />
        <Td
          title={renderGrossInfo()}
          content={
            item.taskType === 'LQA'
              ? `${Math.floor(
                  Number(item.totalGrossAmount ?? item.grossAmount) / 250
                )} 시간 ${
                  Number(item.totalGrossAmount ?? item.grossAmount) % 250 > 0
                    ? '30'
                    : '00'
                }분`
              : item.totalGrossAmount ?? item.grossAmount
          }
        />
        <Td
          title={renderNetInfo()}
          content={
            item.taskType === 'LQA'
              ? `${Math.floor(
                  Number(item.totalNetAmount ?? item.netAmount) / 250
                )} 시간 ${
                  Number(item.totalNetAmount ?? item.netAmount) % 250 > 0
                    ? '30'
                    : '00'
                }분`
              : item.totalNetAmount ?? item.netAmount
          }
        />
        <Td
          title={
            <div data-can-assign-worker={item.canAssignWorker}>작업자</div>
          }
          sx={{
            '.content > *': {
              justifyContent: 'flex-start'
            }
          }}
          content={
            item.canAssignWorker ? (
              <button
                onClick={() => navigateToAssignPage(item)}
                style={{
                  cursor: 'pointer',
                  fontFamily: '"IBM Plex Sans", sans-serif',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '22px',
                  letterSpacing: '-0.25px',
                  textAlign: 'left',
                  color: '#9B51E0'
                }}
              >
                {item.totalWorkerList.length > 0 ? (
                  <TPAvatarGroupNew userList={item.totalWorkerList} />
                ) : (
                  '배정하기'
                )}
              </button>
            ) : (
              <Box
                sx={{
                  cursor: 'not-allowed',
                  '.TPAvatarGroupNew': {
                    justifyContent: 'flex-start'
                  }
                }}
                onClick={() => {
                  toastOpen({
                    text: '해당 작업에 대한 작업자 배정 권한이 없습니다.',
                    severity: 'error'
                  });
                }}
              >
                {Array.isArray(item.totalWorkerList) &&
                item.totalWorkerList.length > 0 ? (
                  <TPAvatarGroupNew userList={item.totalWorkerList} />
                ) : (
                  '배정중'
                )}
              </Box>
            )
          }
        />
      </tr>

      {Array.isArray(item?.languageSubTaskList)
        ? item.languageSubTaskList
            .sort((a: any, b: any) => {
              const d = a.languageSubTaskId - b.languageSubTaskId;
              return isNaN(d) ? 0 : d;
            })
            .map((task: any, i: number) => {
              return (
                <tr
                  key={i}
                  style={{
                    borderLeftWidth: '3px',
                    borderLeftStyle: 'solid',
                    borderLeftColor: getTaskStatusLabelColor(
                      item.languageTaskStatus
                    ),
                    borderBottom: '1px solid #ececec',
                    borderRight: '1px solid #ececec',
                    backgroundColor: '#f9f9f9'
                  }}
                  data-task-status={item.languageTaskStatus}
                >
                  <Td
                    title="작업 분량"
                    content={
                      <div className="tooltip-wrapper">
                        {item.taskType === 'LQA'
                          ? `${Math.floor(
                              Number(task.taskAmount ?? 0) / 250
                            )} 시간 ${
                              Number(task.taskAmount ?? 0) % 250 > 0
                                ? '30'
                                : '00'
                            }분`
                          : task.taskAmount ?? 0}
                        {task.memo === '' || task.memo === null ? (
                          <IconMessageOff width={14} height={14} />
                        ) : (
                          task.memo && (
                            <SimpleTooltip message={task.memo}>
                              <IconMessageOn width={14} height={14} />
                            </SimpleTooltip>
                          )
                        )}
                      </div>
                    }
                    style={{
                      minHeight: 46,
                      paddingLeft: 14,
                      paddingRight: 14,
                      whiteSpace: 'nowrap'
                    }}
                  />
                  <td colSpan={11}>
                    <div className="workers">
                      {/* <WorkerList list={task.workerList} /> */}
                      {formatWorkerList(task.workerList)
                        .sort(compareWorkerType)
                        .map((worker: any, i: number, workers: any[]) => {
                          const isMe =
                            worker?.userId &&
                            worker?.userId !== 0 &&
                            myInfo?.id &&
                            worker?.userId === myInfo?.id;

                          const isPrevComplete =
                            i === 0 ||
                            (i === 1 &&
                              workers[i - 1].userId !== 0 &&
                              workers[i - 1] &&
                              workers[i - 1]?.workerStatus === 'COMPLETE') ||
                            (i === 2 &&
                              workers[i - 1].userId !== 0 &&
                              workers[i - 1] &&
                              workers[i - 1]?.workerStatus === 'COMPLETE') ||
                            (workers[i - 1].userId === 0 &&
                              workers[i - 2]?.workerStatus === 'COMPLETE') ||
                            workers.length === 1;

                          return (
                            <Fragment key={i}>
                              {i !== 0 && (
                                <IcoMenuChevronDown
                                  fill="#D9D9D9"
                                  style={{ rotate: '-90deg' }}
                                />
                              )}
                              <div
                                className={cx({
                                  worker: true,
                                  [worker.workerStatus]: true
                                })}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: 5,
                                    flexGrow: 1
                                  }}
                                >
                                  {worker.workerStatus ? (
                                    isMe && isPrevComplete ? (
                                      <MyWorkRowStatusButton
                                        taskStatus={worker.workerStatus}
                                        workerType={worker.workerType}
                                        workerId={worker.workerId}
                                        workerList={task.workerList}
                                        fileRegisterStatus={
                                          item.fileRegisterStatus
                                        }
                                        workType={'allTask'}
                                        refetch={async () => {
                                          try {
                                            await queryClient.invalidateQueries(
                                              ['/languageTask']
                                            );
                                            // 필요한 경우 강제로 리패치
                                            await queryClient.refetchQueries(
                                              DashboardKeys.Deep4(
                                                item.languageTaskId
                                              )
                                            );
                                          } catch (error) {
                                            console.error(
                                              'Refetch failed:',
                                              error
                                            );
                                          }
                                        }}
                                      />
                                    ) : (
                                      renderLanguageSubTaskStatus(
                                        worker.workerStatus
                                      )
                                    )
                                  ) : (
                                    '미배정'
                                  )}
                                </div>
                                <Avatar worker={worker} size={30} />
                                <div
                                  style={{
                                    whiteSpace: 'nowrap'
                                  }}
                                >
                                  <span
                                    className={cx({
                                      role: true,
                                      isoutsourcing: worker.isOutsourceOrNot
                                    })}
                                  >
                                    <span className="outsource">
                                      {worker.isOutsourceOrNot ? '외주' : ''}
                                    </span>
                                    {getWorkerTypeLabel(worker.workerType)}
                                  </span>
                                </div>
                              </div>
                            </Fragment>
                          );
                        })}
                    </div>
                  </td>
                </tr>
              );
            })
        : null}
    </tbody>
  );
};
