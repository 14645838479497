import api from '@api/Api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@hooks/modal/useToast';

/**
 * /languageSubTask
 * @description 담당자 배정 & 담당자 변경
 * @returns {{ postLanguageSubTask }}
 */

export const usePostLanguageSubTask = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { openHandle: toastOpen } = useToast();

  const postData = async (payload: any) => {
    return await api.post(`/languageSubTask`, payload);
  };
  //todo
  const putStatus = async (payload: any) => {
    return await api.put(`/languageTask/status`, payload);
  };
  const putFileStatus = async (payload: any) => {
    return await api.put(`/languageTask/fileRegisterStatus`, payload);
  };

  const { mutate: putLanguageTask } = useMutation({
    mutationFn: putStatus,
    onSuccess: (variables) => {
      queryClient.invalidateQueries(['/myWork']);
      queryClient.invalidateQueries(['/languageTask']);
      queryClient.invalidateQueries(['DashboardKeys']);
      queryClient.invalidateQueries(['/task']);
      toastOpen({ text: '상태가 변경되었습니다.', severity: 'success' });
    },
    onError: (error: any) => {
      toastOpen({ text: '상태 변경에 실패했습니다.', severity: 'error' });
    }
  });

  const { mutateAsync: postLanguageSubTask } = useMutation({
    mutationFn: postData,
    onSuccess: async (variables) => {
      await Promise.all([
        queryClient.invalidateQueries(['/task']),
        queryClient.invalidateQueries(['/projectDetail']),
        queryClient.invalidateQueries(['/languageTask']),
        queryClient.invalidateQueries({
          queryKey: ['DashboardKeys'],
          refetchType: 'all'
        })
        // queryClient.refetchQueries(['DashboardKeys', 'Deep2'])
      ]);

      toastOpen({
        text: '작업자 배정이 완료되었습니다.',
        severity: 'success'
      });
    },
    onError: (error: any) => {
      toastOpen({ text: '작업자 배정에 실패했습니다.', severity: 'error' });
    }
  });

  const { mutate: postModifyLanguageSubTask } = useMutation({
    mutationFn: postData,
    onSuccess: (variables) => {
      queryClient.invalidateQueries(['/myWork']);
      queryClient.invalidateQueries(['/languageTask']);

      toastOpen({ text: '담당자 배정이 수정되었습니다.' });
    },
    onError: (error: any) => {
      toastOpen({
        text: '담당자 배정 수정에 실패했습니다.',
        severity: 'error'
      });
    }
  });

  const { mutate: putFileStatusTask } = useMutation({
    mutationFn: putFileStatus,
    onSuccess: (variables) => {
      queryClient.invalidateQueries(['DashboardKeys']);
      queryClient.invalidateQueries(['/task']);
      toastOpen({
        text: '작업 파일 상태가 수정되었습니다.',
        severity: 'success'
      });
    },
    onError: (error: any) => {
      toastOpen({
        text: '작업 파일 상태 수정을 실패하였습니다.',
        severity: 'error'
      });
    }
  });

  return {
    postLanguageSubTask,
    postModifyLanguageSubTask,
    putLanguageTask,
    putFileStatusTask
  };
};
