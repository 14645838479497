import { css } from '@emotion/react';
import { Color } from '@common/styles/color';
import { createTheme } from '@mui/material/styles';
import styled from '@emotion/styled';
import { IconOutsourcingOnUrl } from '@src/resources/icon';

export const JobDetailStyle = css`
  width: 756px;
  max-width: 100%;
  text-align: left !important;
  display: flex;
  flex-direction: column;
  font-family: 'IBM Plex Sans', sans-serif;

  .task-type {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.25px;
    text-align: left;
    padding: 0px 4px;
    color: #727272;
    background-color: #f3f3f3;
    margin: auto;
    border-radius: 2px;
    white-space: nowrap;
  }

  .task-title {
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: -0.25px;
    text-align: left;
    color: #242424;
    white-space: break-spaces;
    word-break: break-all;
    text-align: center;

    margin-top: 6px;
    margin-bottom: 40px;
  }

  .assign-button {
    padding: 5px 14px;
    border-radius: 2px;
    border: 1px solid #ececec;
    border-radius: 2px;
    margin-left: auto;
    margin-bottom: 8px;
  }

  .simple-info {
    background-color: #fafafa;
    padding: 10px 16px;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    gap: 2px;

    .row {
      display: flex;
      align-items: center;
      gap: 8px;

      .status {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        &.active {
          color: #e6311a;
        }
      }
      .languages {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
      }
    }
    .language-task-title {
      display: flex;
      align-items: center;
      gap: 4px;
      white-space: break-spaces;
      word-break: break-all;
      color: #727272;
    }
  }

  .is-boracat {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.25px;
    text-align: left;
    color: #727272;
    margin: 16px 0;

    .content {
      display: flex;
      flex-direction: row;
      gap: 28px;
      .option {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        svg {
          display: none;
        }
        &.active {
          color: #9b51e0;
          svg {
            display: block;
          }
        }
      }
    }
  }

  .title {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.25px;
    text-align: left;
    color: #242424;
  }

  .amounts {
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .left {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        .values {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;

          background-color: #f5f5f5;
          padding: 2px 6px;
          border-radius: 2px;

          font-size: 12px;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: -0.25px;
          text-align: left;
          color: #aaaaaa;

          .val {
            color: #727272;
          }
        }
      }

      .right {
        .tm-match {
          background-color: #f5f5f5;
          padding: 2px 6px;
          border-radius: 2px;
          color: #727272;

          font-size: 12px;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: -0.25px;
          text-align: left;

          .val {
            color: #aaa;
            i {
              color: #9b51e0;
              font-style: normal;
            }
          }
        }
      }
    }

    .content {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      justify-content: center;
      column-gap: 6px;
      margin-top: 6px;
      margin-bottom: 16px;

      .name {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.25px;
        text-align: right;
        color: #727272;
        text-align: center;
      }

      .value {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.25px;
        text-align: right;
        color: #242424;
        text-align: center;
      }
    }
  }

  .dates {
    margin-top: 16px;

    .content {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;

      .item {
        width: 162px;

        .name {
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: -0.25px;
          color: #242424;
        }

        .value {
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: -0.25px;
          color: #727272;
        }
      }
    }
  }

  .subtasks {
    margin-top: 16px;

    .title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .left {
      }
      .right {
        color: red;
      }
    }

    .amounts {
      display: grid;
      grid-template-columns: max-content 16px auto;
      align-items: center;
      column-gap: 14px;

      .amount {
        font-size: 12px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: -0.25px;
        text-align: left;
        color: #727272;
        background-color: #f5f5f5;
        padding: 2px 6px;
        min-width: 48px;
        text-align: center;
      }

      .message-tooltip {
        width: 16px;
        height: 16px;
      }

      .workers {
        display: grid;
        grid-template-columns: 1fr 15px 1fr 15px 1fr;
        align-items: center;
        column-gap: 0px;

        .worker {
          display: grid;
          grid-template-columns: auto min-content min-content;
          align-items: center;
          column-gap: 6px;
          white-space: nowrap;
          padding: 2px 4px;

          .status {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 6px;
            padding: 2px 6px;
          }

          &.PROGRESS {
            background-color: #f0e44220;
          }
        }
        > svg {
          position: relative;
          left: 3px;
        }
        .role {
          position: relative;
          padding-right: 20px;
          &.isoutsourcing {
            padding-top: 16px;
            .outsource {
              position: absolute;
              top: 0px;
              margin-bottom: 2px;
              padding: 0 3px 0 10px;
              height: 13px;
              line-height: 13px;
              text-align: left;
              font-size: 10px;
              color: #9b51e0;
              background: rgba(155, 81, 224, 0.06) url(${IconOutsourcingOnUrl})
                no-repeat left 2px center;
              border-radius: 2px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .description {
    margin-top: 16px;
    min-height: 2rem;

    white-space: pre-wrap;
    word-break: break-all;
  }

  .links {
    margin-top: 16px;
    min-height: 2rem;
  }

  .dot {
    width: 2px;
    height: 2px;
    background-color: currentColor;
    border-radius: 50%;
  }

  dd {
    word-wrap: break-word;
  }
  .linkList {
    a {
      text-transform: none;
    }
    button {
      display: none;
    }
    .show {
      display: inline-block;
    }
  }

  .totalRate {
    padding: 16px 0;
    border-top: 1px solid #eeedf4;
    border-bottom: 1px solid #eeedf4;
    .amountInfo {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 16px;
      > .item {
        font-size: 14px;
      }
      > .value {
        font-size: 14px;
        font-weight: 700;
      }
    }
    .title {
      text-align: left;
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 8px;
    }
    .color--red {
      color: ${Color.compulsory};
    }
    .inputTable {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      justify-content: space-between;
      .inputTableCell {
        border: 1px solid #d5d3e4;
        font-size: 14px;
        width: calc(50% - 8px);
        display: flex;
        background-color: #fff; //disable => #f6f6fa;
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px;
        border-radius: 12px;
        div {
          text-align: left;
          width: 44%;
        }
        input {
          width: 52%;
          text-align: right;
          border: none;
          height: 22px;
          :disabled {
            background-color: #f6f6fa;
          }
        }
        &.disabled {
          background-color: #f6f6fa;
        }
      }
    }
    .gross {
      margin-top: 20px;
      .value {
        color: #9a9cb5;
        text-align: right;
      }
    }
    .net {
      margin-top: 8px;
      .value {
        color: #9a9cb5;
        text-align: right;
      }
    }
    .gross,
    .net {
      display: flex;
      justify-content: space-between;
      gap: 12px;
      align-items: center;
      padding: 0 20px;
      width: 100%;
      height: 56px;
      font-size: 14px;
      text-align: left;
      background: #f6f6fa;
      border: 1px solid #d5d3e4;
      border-radius: 12px;
      .value {
        width: 100%;
      }
      .w-37 {
        width: 37px;
      }
      .w-53 {
        width: 53px;
      }
    }
  }

  ul.link--list {
    li.link {
      border-bottom: 1px solid #eeedf4;
      display: flex;
      align-items: center;
      padding: 17px 4px;
      width: 100%;
      background: #ffffff;
      word-break: break-all;
      transition: 0.1s;
      a {
        width: 100%;
        height: 100%;
      }
      :hover {
        cursor: pointer;
        background: #f9f5fd;
      }
    }
  }
  .descriptionArea {
    textarea {
      font-size: 14px;
      line-height: 160%;
      color: #2c3558;
      border: none;
      width: 100%;
      resize: none;
      outline: none;
    }
  }
  .workBox {
    display: flex;
    flex-direction: column;
    gap: 9px;
    .step--avatar {
      display: flex;
      align-items: center;
      gap: 12px;
      .step {
        width: 50px;
        height: 32px;
        line-height: 34px;
        text-align: center;
      }
    }
  }
`;

export const AssignTheme = createTheme({
  typography: { fontFamily: 'Spoqa Han Sans Neo', fontSize: 16 },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderBottom: '1px solid  #EEEDF4',
          '&.Mui-expanded, &.Mui-expanded:first-of-type': {
            margin: 0
          }
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          '&:before': { display: 'none' },
          '&.Mui-expanded, &.Mui-expanded:first-of-type': {
            margin: 0
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: 0,
          '&.Mui-expanded': {
            minHeight: 0
          },
          '&.disable:hover': {
            cursor: 'default'
          }
        },
        content: {
          '& > dl:first-of-type': { width: '26%' },
          '& > dl:nth-of-type(2)': { width: '24%' },
          '& > dl:nth-of-type(3)': { width: '24%' },
          '& > dl:last-of-type': { width: '26%' },
          '& dt': { color: '#9A9CB5' },
          '&.Mui-expanded': {
            margin: '12px 0'
          }
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          background: '#F9F8FC',
          padding: 0,
          '& > div': { borderTop: '1px solid #EEEDF4' },
          '& > div:first-of-type': { borderTop: 0 }
        }
      }
    }
  }
});

interface ISubTaskKeyBox {
  selectSubList: boolean;
}
export const SubTaskKeyBox = styled.div<ISubTaskKeyBox>`
  display: flex;
  justify-content: flex-start;
  gap: 40px;
  div.item {
    display: flex;
    gap: 2px;
    font-size: 14px;
    span.name {
      color: #9a9cb5;
    }
    span.value {
      padding: 0 6px;
      &.key {
        ${({ selectSubList }) => {
          if (selectSubList)
            return css`
              background-color: #ffb880;
            `;
        }}
      }
    }
  }
`;

interface ISubTaskBox {
  selectSubList: boolean;
}

export const SubTaskBox = styled.div<ISubTaskBox>`
  padding: 24px 40px 25px;
  background-color: #fafaff;
  .amountMismatch {
    color: ${Color.compulsory};
    font-size: 14px;
    margin-bottom: 4px;
  }
  .key--amount {
    display: flex;
    height: 22px;
    align-items: center;
    font-size: 14px;
    gap: 40px;
    .key,
    .amount {
      height: 100%;
      .item {
        height: 22px;
        color: #9a9cb5;
        margin-right: 8px;
      }
      .value {
        height: 22px;
        &.key {
          padding: 2px 4px;
          ${({ selectSubList }) => {
            if (selectSubList)
              return css`
                background-color: #ffb880;
              `;
          }}
        }
      }
    }
  }
  .work--box--container {
    margin-top: 22px;
    display: flex;
    align-items: center;
    gap: 40px;
  }
`;
