import api from '@api/Api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@hooks/modal/useToast';
import { apiKeys } from '@src/pages/myTask/MyTaskTab.page';
import { DashboardKeys } from '@src/pages/dashboard/keys/DashboardKeys';

/**
 * /myWork/status
 * @discription 내 작업 상태 변경
 * @returns {{ putStatus }}
 */

export const usePutMyWorkStatus = (
  workType: string,
  languageTaskId?: string
) => {
  const queryClient = useQueryClient();
  const { openHandle: toastOpen } = useToast();

  const putData = async (payload: {
    workerId: string;
    workerStatus: string;
  }) => {
    return await api.put('/myWork/status', { ...payload });
  };

  const { mutateAsync: putStatus } = useMutation({
    mutationFn: putData,
    onSuccess: (data, variables) => {
      if (workType === 'allTask') {
        // queryClient.invalidateQueries({
        //   predicate: (query) =>
        //     ['listByDay', apiKeys.listByDay()].includes(
        //       query.queryKey[0] as stringㅌ
        //     )
        // });
      } else if (workType === 'myTask') {
        queryClient.invalidateQueries({
          predicate: (query) =>
            ['/myWork', 'myWork'].includes(query.queryKey[0] as string)
        });
      }
      queryClient.invalidateQueries(['/task']);
      queryClient.invalidateQueries(['/projectDetail']);
      queryClient.invalidateQueries(['/languageTask']);
      queryClient.invalidateQueries({
        queryKey: ['DashboardKeys'],
        refetchType: 'all'
      });
      queryClient.refetchQueries(DashboardKeys.Deep4(languageTaskId));
      toastOpen({ text: '작업 상태가 변경되었습니다.', severity: 'success' });
    },
    onError: (error: any) => {
      toastOpen({ text: '작업 상태 변경에 실패했습니다.', severity: 'error' });
    }
  });

  return { putStatus, toastOpen };
};
