import React, { useEffect, useState, PureComponent, useMemo } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import { format } from 'date-fns';
import { Wrap } from './PerformanceStats.style';

import { TPSelectCheckSearch } from '@src/units/select/TPSelect.checkSearch';
import { TPButtonActionCalendar } from '@src/units/button/TPButton.ActionCalendar';
import { useRangeCalendar } from '@src/hooks/modal/useRangeCalendar';
import { useGetProjectList } from '@src/hooks/statistics/common/getProjectList';
import { usePmsFilter } from '@src/hooks/statistics/common/usePmsFilter';
import Footer from '@src/layouts/footer/Footer';
import { useGetWorkerList } from '@src/hooks/statistics/common/getWorkerList';
import { TPSelectCheckWorker } from '@src/units/select/TPSelect.checkWorker';
import { useGetFilter } from '@src/hooks/statistics/searchFilter';
import { useWorkerPerformance } from '@src/hooks/statistics/postWorkerPerformance';
import { IconArrowRightGray, IconBasicUserUrl } from '@src/resources/icon';
import { LANGUAGE } from '@src/common/config/Code';
import GraphPerformance, { WorkerList } from './GraphPerformance';
import Loading from '../components/Loading';

interface IWorkerPerformancePage {
  projectIdList: string[];
  startDateTime: Date;
  endDateTime: Date;
  workUserIdList: string[];
}

interface IWorkerPerformanceData {
  date: Date;
  performanceData: WorkerList[];
}

const WorkerPerformancePage = () => {
  const [projectListFilter, setProjectListFilter] = useState<
    { code: string; label: string }[]
  >([]);
  const { data: workerList } = useGetWorkerList();
  const { filterData, Component: FilterButton } =
    useGetFilter('WORKER_PERFORMANCE');
  const { projectList } = useGetProjectList();
  const {
    dashboardFilter,
    dashboardFilterProxy,
    searchDateFilter,
    setSearchDateFilter,
    dateFilter,
    setDateFilter
  } = usePmsFilter(filterData);
  const { postFilter, responseData, loading } =
    useWorkerPerformance('WORKER_PERFORMANCE');
  const [performanceData, setPerformanceData] =
    useState<IWorkerPerformanceData>();
  const { openHandle, Component: Calendar } = useRangeCalendar({
    dateType: 'searchDateRange'
  });
  const [filtersHeight, setFiltersHeight] = useState<number>(0);
  const [activeTab, setActiveTab] = useState<{ [key: number]: string }>({});
  const workerTypeOrder = ['TRANSLATOR', 'FIRST_CHECKER', 'SECOND_CHECKER'];
  const handlePostData = ({
    projectIdList,
    startDateTime,
    endDateTime,
    workUserIdList
  }: IWorkerPerformancePage) => {
    if (projectIdList[0] === 'ALL') {
      projectIdList = projectList.map((item: { code: string }) =>
        parseInt(item.code)
      );
    }
    const startTime = format(new Date(startDateTime), 'yyyy-MM-dd');
    const endTime = format(new Date(endDateTime), 'yyyy-MM-dd');
    const result = {
      projectIdList,
      startDateTime: startTime,
      endDateTime: endTime,
      workerUserList: workUserIdList
    };
    postFilter(result);
  };

  const showSaveButton = useMemo(() => {
    return (
      !dashboardFilter.projectIdList.includes('ALL') ||
      dashboardFilter.workUserIdList.length > 0 ||
      format(new Date(searchDateFilter.startDateTime), 'yyyy.MM.dd') !==
        format(new Date(), 'yyyy.MM.dd') ||
      format(new Date(searchDateFilter.endDateTime), 'yyyy.MM.dd') !==
        format(new Date(), 'yyyy.MM.dd') ||
      filterData !== null
    );
  }, [dashboardFilter, searchDateFilter, filterData]);

  //번역, 1차 검수, 2차 검수 함수
  const renderWorkerTypeList = (
    workerTypeList: {
      workerTypeName: string;
      workerTypePercentage: number;
      workerTypeAmount: number;
    }[],
    workerTypeOrder: string[]
  ) => {
    return workerTypeList
      .sort(
        (a, b) =>
          workerTypeOrder.indexOf(a.workerTypeName) -
          workerTypeOrder.indexOf(b.workerTypeName)
      )
      .map((workerType, workerTypeIndex) => (
        <li key={workerTypeIndex}>
          <strong
            className={
              workerType.workerTypeName === 'TRANSLATOR'
                ? 'translator'
                : workerType.workerTypeName === 'FIRST_CHECKER'
                ? 'firstChecker'
                : 'secondChecker'
            }
          >
            {workerType.workerTypeName === 'TRANSLATOR'
              ? '번역'
              : workerType.workerTypeName === 'FIRST_CHECKER'
              ? '1차검수'
              : '2차검수'}
          </strong>
          <p>
            <span>
              {isNaN(workerType.workerTypePercentage)
                ? '0%'
                : workerType.workerTypePercentage + '%'}
            </span>
            <span>
              {isNaN(workerType.workerTypeAmount)
                ? '0 단어 . 자'
                : workerType.workerTypeAmount.toLocaleString() + ' 단어 . 자'}
            </span>
          </p>
        </li>
      ));
  };

  const renderAmountListItem = (
    label: string,
    value: number | string,
    diff?: number,
    isPercentage: boolean = false
  ) => {
    const formattedValue =
      typeof value === 'number' ? value.toLocaleString() : value;
    return (
      <li>
        <span>{label}</span>
        <strong>
          {formattedValue}
          {isPercentage && '%'}
          {diff !== undefined && diff !== 0 && (
            <span
              className={diff > 0 ? 'positive' : diff < 0 ? 'negative' : ''}
            >
              ({diff > 0 ? '+' : ''}
              {diff.toLocaleString()}
              {label === '파트 평균 부하율' && '%'})
            </span>
          )}
        </strong>
      </li>
    );
  };

  useEffect(() => {
    if (projectList.length !== projectListFilter.length) {
      setProjectListFilter(
        projectList.filter((item: { code: string }) => item?.code !== '')
      );
    }
  }, [projectList]);

  useEffect(() => {
    const filtersElement = document.querySelector('.filters');
    if (filtersElement) {
      const totalHeight =
        filtersElement.clientHeight +
        parseInt(window.getComputedStyle(filtersElement).marginTop);
      setFiltersHeight(totalHeight + 56); // 56은 헤더 높이 + 2px는 언어페어 여백
    }
  }, []);

  useEffect(() => {
    if (performanceData) {
      const initialActiveTabs = performanceData.performanceData.reduce(
        (acc, worker) => {
          acc[worker.userId] = '0';
          return acc;
        },
        {} as { [key: number]: string }
      );
      setActiveTab(initialActiveTabs);
    }
  }, [performanceData]);

  useEffect(() => {
    const tableWraps = document.querySelectorAll('.table--wrap');
    tableWraps.forEach((tableWrap) => {
      const table = tableWrap.querySelector('table');
      if (table) {
        const isOverflowing = tableWrap.clientHeight < table.scrollHeight;
        if (isOverflowing) {
          table.querySelector('thead')?.classList.add('overflowing');
        } else {
          table.querySelector('thead')?.classList.remove('overflowing');
        }
      }
    });
  }, [activeTab]);

  useEffect(() => {
    if (responseData) {
      setPerformanceData(responseData);
    }
  }, [responseData]);

  return (
    <section css={Wrap}>
      <div className="filters">
        <div className="fullLookup--title">통계</div>
        <div className="fullLookup--list">
          <div className="name">
            <span>작업자 퍼포먼스</span>
          </div>
          <div className="filter--list">
            <div className="left">
              <TPSelectCheckSearch
                label="프로젝트"
                useOptionLabel
                optionList={[
                  { label: '전체', code: 'ALL' },
                  ...projectListFilter.filter((item: any) => item?.code !== '')
                ]}
                selectOptions={dashboardFilter.projectIdList}
                changeSelect={dashboardFilterProxy.setProjectList}
              />
              <div
                onClick={() => {
                  openHandle('search', searchDateFilter, (newDateFilter) => {
                    setSearchDateFilter(newDateFilter);
                    setDateFilter({
                      startDateTime: new Date(newDateFilter.startDateTime),
                      endDateTime: new Date(newDateFilter.endDateTime)
                    });
                  });
                }}
              >
                <TPButtonActionCalendar
                  calendarType={'normal'}
                  content={
                    searchDateFilter.startDateTime &&
                    searchDateFilter.endDateTime
                      ? `기간 ${format(
                          new Date(searchDateFilter.startDateTime),
                          'yyyy.MM.dd'
                        )}${
                          new Date(searchDateFilter.startDateTime).getTime() ===
                          new Date(searchDateFilter.endDateTime).getTime()
                            ? ''
                            : ' - ' +
                              format(
                                new Date(searchDateFilter.endDateTime),
                                'yyyy.MM.dd'
                              )
                        }`
                      : ''
                  }
                />
              </div>
              <TPSelectCheckWorker
                label="작업자"
                workerList={workerList?.workerList || []}
                showAvatar={true}
                selectOptions={dashboardFilter.workUserIdList}
                changeSelect={dashboardFilterProxy.setWorkUserIdList}
                maxSelections={5}
              />
              <button
                className={`btn--search ${
                  searchDateFilter.startDateTime &&
                  searchDateFilter.endDateTime &&
                  dashboardFilter.workUserIdList.length > 0
                    ? ''
                    : 'disabled'
                }`}
                onClick={() => {
                  if (
                    searchDateFilter.startDateTime &&
                    searchDateFilter.endDateTime &&
                    dashboardFilter.workUserIdList.length > 0
                  ) {
                    handlePostData({
                      projectIdList: dashboardFilter.projectIdList,
                      startDateTime: searchDateFilter.startDateTime,
                      endDateTime: searchDateFilter.endDateTime,
                      workUserIdList: dashboardFilter.workUserIdList
                    });
                  }
                }}
              >
                조회
              </button>
              {showSaveButton && (
                <FilterButton
                  getFilterData={{
                    dashboardFilter: (() => {
                      const { keyword, ...other } = dashboardFilter;
                      return {
                        ...other,
                        projectIdList: dashboardFilter.projectIdList
                      };
                    })(),
                    searchDateFilter: searchDateFilter,
                    dateFilter: dateFilter
                  }}
                />
              )}
            </div>
            {(!dashboardFilter.projectIdList.includes('ALL') ||
              format(new Date(searchDateFilter.startDateTime), 'yyyy.MM.dd') !==
                format(new Date(), 'yyyy.MM.dd') ||
              format(new Date(searchDateFilter.endDateTime), 'yyyy.MM.dd') !==
                format(new Date(), 'yyyy.MM.dd') ||
              dashboardFilter.workUserIdList.length > 0) && (
              <div className="right">
                <button
                  className="btn--reset"
                  onClick={() => {
                    dashboardFilterProxy.clearFilter();
                    setPerformanceData(undefined);
                  }}
                >
                  초기화
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {loading ? (
        <div style={{ width: '100%', height: '500px' }}>
          <Loading />
        </div>
      ) : (
        <div
          className="content--wrap"
          style={{ height: `calc(100vh - ${filtersHeight}px)` }}
        >
          {performanceData?.performanceData.length ? (
            <div className="content--box">
              {performanceData.performanceData.map((item, index) => (
                <div key={item.userId} className="worker--performance">
                  {index === 0 && (
                    <p className="worker--performance--date">
                      {format(
                        new Date(performanceData.date),
                        'yyyy.MM.dd HH:mm'
                      )}
                      <span>기준</span>
                    </p>
                  )}
                  <div className="title">
                    <span className="img">
                      <img
                        src={item.userImgUrl}
                        alt={item.userName}
                        onError={(
                          e: React.SyntheticEvent<HTMLImageElement, Event>
                        ) => {
                          const target = e.target as HTMLImageElement;
                          target.src = IconBasicUserUrl;
                        }}
                      />
                    </span>
                    <strong className="name">{item.userName}</strong>
                  </div>
                  {item.userLanguagePairList.length > 0 &&
                  item.byLanguagePairData.length > 0 ? (
                    <>
                      <ul className="worker--tab">
                        <li
                          key={`${item.userId}-all`}
                          className={
                            activeTab[item.userId] === '0' ? 'active' : ''
                          }
                          onClick={() => {
                            setActiveTab({ ...activeTab, [item.userId]: '0' });
                          }}
                        >
                          <span>전체</span>
                        </li>
                        {item.userLanguagePairList.map(
                          (languagePair, languagePairIndex) => (
                            <li
                              key={`${item.userId}-${languagePairIndex}`}
                              className={
                                activeTab[item.userId] ===
                                `${languagePairIndex + 1}`
                                  ? 'active'
                                  : ''
                              }
                              onClick={() =>
                                setActiveTab({
                                  ...activeTab,
                                  [item.userId]: `${languagePairIndex + 1}`
                                })
                              }
                            >
                              <span>
                                {LANGUAGE.filter(
                                  (v) => v.code === languagePair.startLanguage
                                ).map((lang) => lang.label)}
                              </span>
                              <IconArrowRightGray />
                              <span>
                                {LANGUAGE.filter(
                                  (v) =>
                                    v.code === languagePair.destinationLanguage
                                ).map((lang) => lang.label)}
                              </span>
                            </li>
                          )
                        )}
                      </ul>

                      <div className="worker--data">
                        <div className="graph--wrap">
                          <div className="graph--box">
                            <GraphPerformance
                              activeTab={activeTab}
                              item={item}
                              workerTypeList={
                                item.byLanguagePairData[
                                  parseInt(activeTab[item.userId])
                                ]?.workerTypeList
                              }
                            />
                          </div>
                          <ul className="graph--list">
                            {renderWorkerTypeList(
                              item.byLanguagePairData[
                                parseInt(activeTab[item.userId])
                              ]?.workerTypeList || [],
                              workerTypeOrder
                            )}
                          </ul>
                        </div>
                        <div className="graph--amount">
                          <ul className="amount--list">
                            {renderAmountListItem(
                              '평균 부하율',
                              item.byLanguagePairData[
                                parseInt(activeTab[item.userId])
                              ]?.userAverageLoad || '0',
                              undefined,
                              true
                            )}
                            {renderAmountListItem(
                              '평균 작업 분량',
                              item.byLanguagePairData[
                                parseInt(activeTab[item.userId])
                              ]?.userAverageAmount || '0'
                            )}
                            {renderAmountListItem(
                              '총 작업 분량',
                              item.byLanguagePairData[
                                parseInt(activeTab[item.userId])
                              ]?.userTotalAmount || '0'
                            )}
                            {renderAmountListItem(
                              '파트 평균 부하율',
                              item.byLanguagePairData[
                                parseInt(activeTab[item.userId])
                              ]?.partAverageLoad || '0',
                              item.byLanguagePairData[
                                parseInt(activeTab[item.userId])
                              ]?.diffAverageLoad,
                              true
                            )}
                            {renderAmountListItem(
                              '파트 평균 작업 분량',
                              item.byLanguagePairData[
                                parseInt(activeTab[item.userId])
                              ]?.partAverageAmount || '0',
                              item.byLanguagePairData[
                                parseInt(activeTab[item.userId])
                              ]?.diffAverageAmount
                            )}
                            {renderAmountListItem(
                              '파트 총 작업 분량',
                              item.byLanguagePairData[
                                parseInt(activeTab[item.userId])
                              ]?.partTotalAmount || '0',
                              item.byLanguagePairData[
                                parseInt(activeTab[item.userId])
                              ]?.diffTotalAmount
                            )}
                          </ul>
                        </div>
                        <div className="table--wrap">
                          <table>
                            <colgroup>
                              <col width="*" />
                              <col width="270px" />
                              <col width="300px" />
                              <col width="130px" />
                            </colgroup>
                            <thead>
                              <tr>
                                <th scope="col">프로젝트</th>
                                <th scope="col">작업 비율</th>
                                <th scope="col">평균 작업 분량</th>
                                <th scope="col">총 작업 분량</th>
                              </tr>
                            </thead>
                            <tbody>
                              {item.byLanguagePairData[
                                parseInt(activeTab[item.userId])
                              ]?.projectList?.map((project, index) => (
                                <tr key={index}>
                                  <td className="text--left">
                                    {project.projectName}
                                  </td>
                                  <td>
                                    {isNaN(project.projectPercent)
                                      ? `-%`
                                      : `${project.projectPercent.toLocaleString()}%`}
                                  </td>
                                  <td>
                                    {project.projectAverageAmount.toLocaleString() ||
                                      '0'}
                                  </td>
                                  <td>
                                    {project.projectTotalAmount.toLocaleString() ||
                                      '0'}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="data--none">조회된 데이터가 없습니다.</div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="content--none">
              <p>조회된 데이터가 없습니다.</p>
              <p>
                <strong>조회 조건을 설정</strong>한 후 <strong>조회 </strong>
                버튼을 눌러 파트별 퍼포먼스를 확인할 수 있습니다.
              </p>
            </div>
          )}
          <Footer />
        </div>
      )}

      <Calendar />
    </section>
  );
};

export default WorkerPerformancePage;
